import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueDraggable from "vuedraggable";
import LoadingPlugin from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import themeColors from "./theme-variables/theme.module.scss";

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/vue-tel-input";
import { FILESURL } from "./config";
import "@core/scss/vue/libs/vue-flatpicker.scss";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueDraggable);
// Composition API
Vue.use(VueCompositionAPI);

Vue.prototype.$FILESURL = FILESURL;

// import core styles
require("@core/scss/core.scss");
require("@core/assets/fonts/feather/iconfont.css");
require("@core/assets/fonts/feather/common.css");

// import assets styles
require("@/assets/scss/style.scss");

// Loader
Vue.use(LoadingPlugin, {
  loader: "spinner",
  color: themeColors.primary,
  width: 70,
  zIndex: 1019,
  height: 70,
  backgroundColor: "#000",
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
